import _engine from "engine.io-client";
import _socket from "./socket";
import _componentEmitter from "component-emitter";
import _socket2 from "socket.io-parser";
import _on from "./on";
import _componentBind from "component-bind";
import _debug from "debug";
import _indexof from "indexof";
import _backo from "backo2";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

/**
 * Module dependencies.
 */
var eio = _engine;
var Socket = _socket;
var Emitter = _componentEmitter;
var parser = _socket2;
var on = _on;
var bind = _componentBind;

var debug = _debug("socket.io-client:manager");

var indexOf = _indexof;
var Backoff = _backo;
/**
 * IE6+ hasOwnProperty
 */

var has = Object.prototype.hasOwnProperty;
/**
 * Module exports
 */

exports = Manager;
/**
 * `Manager` constructor.
 *
 * @param {String} engine instance or engine uri/opts
 * @param {Object} options
 * @api public
 */

function Manager(uri, opts) {
  if (!((this || _global) instanceof Manager)) return new Manager(uri, opts);

  if (uri && "object" === typeof uri) {
    opts = uri;
    uri = undefined;
  }

  opts = opts || {};
  opts.path = opts.path || "/socket.io";
  (this || _global).nsps = {};
  (this || _global).subs = [];
  (this || _global).opts = opts;
  this.reconnection(opts.reconnection !== false);
  this.reconnectionAttempts(opts.reconnectionAttempts || Infinity);
  this.reconnectionDelay(opts.reconnectionDelay || 1000);
  this.reconnectionDelayMax(opts.reconnectionDelayMax || 5000);
  this.randomizationFactor(opts.randomizationFactor || 0.5);
  (this || _global).backoff = new Backoff({
    min: this.reconnectionDelay(),
    max: this.reconnectionDelayMax(),
    jitter: this.randomizationFactor()
  });
  this.timeout(null == opts.timeout ? 20000 : opts.timeout);
  (this || _global).readyState = "closed";
  (this || _global).uri = uri;
  (this || _global).connecting = [];
  (this || _global).lastPing = null;
  (this || _global).encoding = false;
  (this || _global).packetBuffer = [];

  var _parser = opts.parser || parser;

  (this || _global).encoder = new _parser.Encoder();
  (this || _global).decoder = new _parser.Decoder();
  (this || _global).autoConnect = opts.autoConnect !== false;
  if ((this || _global).autoConnect) this.open();
}
/**
 * Propagate given event to sockets and emit on `this`
 *
 * @api private
 */


Manager.prototype.emitAll = function () {
  (this || _global).emit.apply(this || _global, arguments);

  for (var nsp in (this || _global).nsps) {
    if (has.call((this || _global).nsps, nsp)) {
      (this || _global).nsps[nsp].emit.apply((this || _global).nsps[nsp], arguments);
    }
  }
};
/**
 * Update `socket.id` of all sockets
 *
 * @api private
 */


Manager.prototype.updateSocketIds = function () {
  for (var nsp in (this || _global).nsps) {
    if (has.call((this || _global).nsps, nsp)) {
      (this || _global).nsps[nsp].id = this.generateId(nsp);
    }
  }
};
/**
 * generate `socket.id` for the given `nsp`
 *
 * @param {String} nsp
 * @return {String}
 * @api private
 */


Manager.prototype.generateId = function (nsp) {
  return (nsp === "/" ? "" : nsp + "#") + (this || _global).engine.id;
};
/**
 * Mix in `Emitter`.
 */


Emitter(Manager.prototype);
/**
 * Sets the `reconnection` config.
 *
 * @param {Boolean} true/false if it should automatically reconnect
 * @return {Manager} self or value
 * @api public
 */

Manager.prototype.reconnection = function (v) {
  if (!arguments.length) return (this || _global)._reconnection;
  (this || _global)._reconnection = !!v;
  return this || _global;
};
/**
 * Sets the reconnection attempts config.
 *
 * @param {Number} max reconnection attempts before giving up
 * @return {Manager} self or value
 * @api public
 */


Manager.prototype.reconnectionAttempts = function (v) {
  if (!arguments.length) return (this || _global)._reconnectionAttempts;
  (this || _global)._reconnectionAttempts = v;
  return this || _global;
};
/**
 * Sets the delay between reconnections.
 *
 * @param {Number} delay
 * @return {Manager} self or value
 * @api public
 */


Manager.prototype.reconnectionDelay = function (v) {
  if (!arguments.length) return (this || _global)._reconnectionDelay;
  (this || _global)._reconnectionDelay = v;
  (this || _global).backoff && (this || _global).backoff.setMin(v);
  return this || _global;
};

Manager.prototype.randomizationFactor = function (v) {
  if (!arguments.length) return (this || _global)._randomizationFactor;
  (this || _global)._randomizationFactor = v;
  (this || _global).backoff && (this || _global).backoff.setJitter(v);
  return this || _global;
};
/**
 * Sets the maximum delay between reconnections.
 *
 * @param {Number} delay
 * @return {Manager} self or value
 * @api public
 */


Manager.prototype.reconnectionDelayMax = function (v) {
  if (!arguments.length) return (this || _global)._reconnectionDelayMax;
  (this || _global)._reconnectionDelayMax = v;
  (this || _global).backoff && (this || _global).backoff.setMax(v);
  return this || _global;
};
/**
 * Sets the connection timeout. `false` to disable
 *
 * @return {Manager} self or value
 * @api public
 */


Manager.prototype.timeout = function (v) {
  if (!arguments.length) return (this || _global)._timeout;
  (this || _global)._timeout = v;
  return this || _global;
};
/**
 * Starts trying to reconnect if reconnection is enabled and we have not
 * started reconnecting yet
 *
 * @api private
 */


Manager.prototype.maybeReconnectOnOpen = function () {
  // Only try to reconnect if it's the first time we're connecting
  if (!(this || _global).reconnecting && (this || _global)._reconnection && (this || _global).backoff.attempts === 0) {
    // keeps reconnection from firing twice for the same reconnection loop
    this.reconnect();
  }
};
/**
 * Sets the current transport `socket`.
 *
 * @param {Function} optional, callback
 * @return {Manager} self
 * @api public
 */


Manager.prototype.open = Manager.prototype.connect = function (fn, opts) {
  debug("readyState %s", (this || _global).readyState);
  if (~(this || _global).readyState.indexOf("open")) return this || _global;
  debug("opening %s", (this || _global).uri);
  (this || _global).engine = eio((this || _global).uri, (this || _global).opts);
  var socket = (this || _global).engine;
  var self = this || _global;
  (this || _global).readyState = "opening";
  (this || _global).skipReconnect = false; // emit `open`

  var openSub = on(socket, "open", function () {
    self.onopen();
    fn && fn();
  }); // emit `connect_error`

  var errorSub = on(socket, "error", function (data) {
    debug("connect_error");
    self.cleanup();
    self.readyState = "closed";
    self.emitAll("connect_error", data);

    if (fn) {
      var err = new Error("Connection error");
      err.data = data;
      fn(err);
    } else {
      // Only do this if there is no fn to handle the error
      self.maybeReconnectOnOpen();
    }
  }); // emit `connect_timeout`

  if (false !== (this || _global)._timeout) {
    var timeout = (this || _global)._timeout;
    debug("connect attempt will timeout after %d", timeout); // set timer

    var timer = setTimeout(function () {
      debug("connect attempt timed out after %d", timeout);
      openSub.destroy();
      socket.close();
      socket.emit("error", "timeout");
      self.emitAll("connect_timeout", timeout);
    }, timeout);

    (this || _global).subs.push({
      destroy: function () {
        clearTimeout(timer);
      }
    });
  }

  (this || _global).subs.push(openSub);

  (this || _global).subs.push(errorSub);

  return this || _global;
};
/**
 * Called upon transport open.
 *
 * @api private
 */


Manager.prototype.onopen = function () {
  debug("open"); // clear old subs

  this.cleanup(); // mark as open

  (this || _global).readyState = "open";
  this.emit("open"); // add new subs

  var socket = (this || _global).engine;

  (this || _global).subs.push(on(socket, "data", bind(this || _global, "ondata")));

  (this || _global).subs.push(on(socket, "ping", bind(this || _global, "onping")));

  (this || _global).subs.push(on(socket, "pong", bind(this || _global, "onpong")));

  (this || _global).subs.push(on(socket, "error", bind(this || _global, "onerror")));

  (this || _global).subs.push(on(socket, "close", bind(this || _global, "onclose")));

  (this || _global).subs.push(on((this || _global).decoder, "decoded", bind(this || _global, "ondecoded")));
};
/**
 * Called upon a ping.
 *
 * @api private
 */


Manager.prototype.onping = function () {
  (this || _global).lastPing = new Date();
  this.emitAll("ping");
};
/**
 * Called upon a packet.
 *
 * @api private
 */


Manager.prototype.onpong = function () {
  this.emitAll("pong", new Date() - (this || _global).lastPing);
};
/**
 * Called with data.
 *
 * @api private
 */


Manager.prototype.ondata = function (data) {
  (this || _global).decoder.add(data);
};
/**
 * Called when parser fully decodes a packet.
 *
 * @api private
 */


Manager.prototype.ondecoded = function (packet) {
  this.emit("packet", packet);
};
/**
 * Called upon socket error.
 *
 * @api private
 */


Manager.prototype.onerror = function (err) {
  debug("error", err);
  this.emitAll("error", err);
};
/**
 * Creates a new socket for the given `nsp`.
 *
 * @return {Socket}
 * @api public
 */


Manager.prototype.socket = function (nsp, opts) {
  var socket = (this || _global).nsps[nsp];

  if (!socket) {
    socket = new Socket(this || _global, nsp, opts);
    (this || _global).nsps[nsp] = socket;
    var self = this || _global;
    socket.on("connecting", onConnecting);
    socket.on("connect", function () {
      socket.id = self.generateId(nsp);
    });

    if ((this || _global).autoConnect) {
      // manually call here since connecting event is fired before listening
      onConnecting();
    }
  }

  function onConnecting() {
    if (!~indexOf(self.connecting, socket)) {
      self.connecting.push(socket);
    }
  }

  return socket;
};
/**
 * Called upon a socket close.
 *
 * @param {Socket} socket
 */


Manager.prototype.destroy = function (socket) {
  var index = indexOf((this || _global).connecting, socket);
  if (~index) (this || _global).connecting.splice(index, 1);
  if ((this || _global).connecting.length) return;
  this.close();
};
/**
 * Writes a packet.
 *
 * @param {Object} packet
 * @api private
 */


Manager.prototype.packet = function (packet) {
  debug("writing packet %j", packet);
  var self = this || _global;
  if (packet.query && packet.type === 0) packet.nsp += "?" + packet.query;

  if (!self.encoding) {
    // encode, then write to engine with result
    self.encoding = true;

    (this || _global).encoder.encode(packet, function (encodedPackets) {
      for (var i = 0; i < encodedPackets.length; i++) {
        self.engine.write(encodedPackets[i], packet.options);
      }

      self.encoding = false;
      self.processPacketQueue();
    });
  } else {
    // add packet to the queue
    self.packetBuffer.push(packet);
  }
};
/**
 * If packet buffer is non-empty, begins encoding the
 * next packet in line.
 *
 * @api private
 */


Manager.prototype.processPacketQueue = function () {
  if ((this || _global).packetBuffer.length > 0 && !(this || _global).encoding) {
    var pack = (this || _global).packetBuffer.shift();

    this.packet(pack);
  }
};
/**
 * Clean up transport subscriptions and packet buffer.
 *
 * @api private
 */


Manager.prototype.cleanup = function () {
  debug("cleanup");
  var subsLength = (this || _global).subs.length;

  for (var i = 0; i < subsLength; i++) {
    var sub = (this || _global).subs.shift();

    sub.destroy();
  }

  (this || _global).packetBuffer = [];
  (this || _global).encoding = false;
  (this || _global).lastPing = null;

  (this || _global).decoder.destroy();
};
/**
 * Close the current socket.
 *
 * @api private
 */


Manager.prototype.close = Manager.prototype.disconnect = function () {
  debug("disconnect");
  (this || _global).skipReconnect = true;
  (this || _global).reconnecting = false;

  if ("opening" === (this || _global).readyState) {
    // `onclose` will not fire because
    // an open event never happened
    this.cleanup();
  }

  (this || _global).backoff.reset();

  (this || _global).readyState = "closed";
  if ((this || _global).engine) (this || _global).engine.close();
};
/**
 * Called upon engine close.
 *
 * @api private
 */


Manager.prototype.onclose = function (reason) {
  debug("onclose");
  this.cleanup();

  (this || _global).backoff.reset();

  (this || _global).readyState = "closed";
  this.emit("close", reason);

  if ((this || _global)._reconnection && !(this || _global).skipReconnect) {
    this.reconnect();
  }
};
/**
 * Attempt a reconnection.
 *
 * @api private
 */


Manager.prototype.reconnect = function () {
  if ((this || _global).reconnecting || (this || _global).skipReconnect) return this || _global;
  var self = this || _global;

  if ((this || _global).backoff.attempts >= (this || _global)._reconnectionAttempts) {
    debug("reconnect failed");

    (this || _global).backoff.reset();

    this.emitAll("reconnect_failed");
    (this || _global).reconnecting = false;
  } else {
    var delay = (this || _global).backoff.duration();

    debug("will wait %dms before reconnect attempt", delay);
    (this || _global).reconnecting = true;
    var timer = setTimeout(function () {
      if (self.skipReconnect) return;
      debug("attempting reconnect");
      self.emitAll("reconnect_attempt", self.backoff.attempts);
      self.emitAll("reconnecting", self.backoff.attempts); // check again for the case socket closed in above events

      if (self.skipReconnect) return;
      self.open(function (err) {
        if (err) {
          debug("reconnect attempt error");
          self.reconnecting = false;
          self.reconnect();
          self.emitAll("reconnect_error", err.data);
        } else {
          debug("reconnect success");
          self.onreconnect();
        }
      });
    }, delay);

    (this || _global).subs.push({
      destroy: function () {
        clearTimeout(timer);
      }
    });
  }
};
/**
 * Called upon successful reconnect.
 *
 * @api private
 */


Manager.prototype.onreconnect = function () {
  var attempt = (this || _global).backoff.attempts;
  (this || _global).reconnecting = false;

  (this || _global).backoff.reset();

  this.updateSocketIds();
  this.emitAll("reconnect", attempt);
};

export default exports;